import service from '@/utils/request'; // @Tags LinkCate
// @Summary 创建LinkCate
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.LinkCate true "创建LinkCate"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /linkCate/createLinkCate [post]

export var createLinkCate = function createLinkCate(data) {
  return service({
    url: "/linkCate/createLinkCate",
    method: 'post',
    data: data
  });
}; // @Tags LinkCate
// @Summary 删除LinkCate
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.LinkCate true "删除LinkCate"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /linkCate/deleteLinkCate [delete]

export var deleteLinkCate = function deleteLinkCate(data) {
  return service({
    url: "/linkCate/deleteLinkCate",
    method: 'delete',
    data: data
  });
}; // @Tags LinkCate
// @Summary 删除LinkCate
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除LinkCate"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /linkCate/deleteLinkCate [delete]

export var deleteLinkCateByIds = function deleteLinkCateByIds(data) {
  return service({
    url: "/linkCate/deleteLinkCateByIds",
    method: 'delete',
    data: data
  });
}; // @Tags LinkCate
// @Summary 更新LinkCate
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.LinkCate true "更新LinkCate"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /linkCate/updateLinkCate [put]

export var updateLinkCate = function updateLinkCate(data) {
  return service({
    url: "/linkCate/updateLinkCate",
    method: 'put',
    data: data
  });
}; // @Tags LinkCate
// @Summary 用id查询LinkCate
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.LinkCate true "用id查询LinkCate"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /linkCate/findLinkCate [get]

export var findLinkCate = function findLinkCate(params) {
  return service({
    url: "/linkCate/findLinkCate",
    method: 'get',
    params: params
  });
}; // @Tags LinkCate
// @Summary 分页获取LinkCate列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取LinkCate列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /linkCate/getLinkCateList [get]

export var getLinkCateList = function getLinkCateList(params) {
  return service({
    url: "/linkCate/getLinkCateList",
    method: 'get',
    params: params
  });
};